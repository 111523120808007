<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="secondary"
        class="mr-1"
        :to="{ name: $route.meta.listEmployeesRoute }"
        >RETURN
      </b-button>
      <b-button variant="danger" class="mr-1" @click="deleteEmployee"
        >DELETE
      </b-button>
    </div>
    <b-card>
      <b-row>
        <b-col :key="keyPhoto" md="3">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div
              v-if="form.file != ''"
              :class="['avatar-container', { backdrop: form.remove }]"
            >
              <div
                v-if="form.remove"
                class="remove-temporal-avatar"
                @click="clearFileData"
              >
                <div>
                  <feather-icon icon="XIcon" size="35" />
                </div>
              </div>
              <b-avatar
                ref="previewEl"
                :src="form.file"
                :text="avatarText(userData.fullName)"
                size="130px"
                rounded
              />
            </div>

            <b-avatar
              v-else
              :src="userData.url"
              :text="avatarText(userData.fullName)"
              :variant="`light-primary`"
              size="130px"
              rounded
            />
            <div class="ml-2">
              <b-button
                v-if="form.file != ''"
                variant="outline-success"
                size="sm"
                @click="updatePhoto"
              >
                <feather-icon icon="ImageIcon" class="d-inline mr-25" />
                <span class="d-none d-sm-inline">Save</span>
              </b-button>
              <b-button
                v-else
                variant="outline-primary"
                size="sm"
                @click="addFile"
              >
                <b-form-file
                  ref="refInputEl"
                  class="d-none"
                  @change="fillFileData"
                />
                <feather-icon icon="ImageIcon" class="d-inline mr-25" />
                <span class="d-none d-sm-inline">Upload</span>
              </b-button>
            </div>
          </div>
        </b-col>

        <b-col md="8">
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="UserIcon" class="mr-75" />
                  EMPLOYEE
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="userData.empoye_name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="PhoneIcon" class="mr-75" />
                  PHONE NUMBER
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="userData.mobile"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="HomeIcon" class="mr-75" />
                  DEPARTMENT
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="userData.department"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="MailIcon" class="mr-75" />
                  EMAIL
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="userData.work_email"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="UserCheckIcon" class="mr-75" />
                  ROL
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="userData.rol"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  START DATE
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="userData.start_date"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label-cols-lg="5"
                label-cols-md="5"
                label-cols-sm="4"
                label-cols="12"
                content-cols-lg="7"
                content-cols-md="7"
                content-cols-sm="8"
                content-cols="12"
              >
                <template #label>
                  <feather-icon icon="CircleIcon" class="mr-75" />
                  STATUS
                </template>
                <b-skeleton v-if="!finishedLoading" type="input" />
                <b-form-input
                  v-if="finishedLoading"
                  :value="userData.status === 1 ? 'Active' : 'Inactive'"
                  :style="
                    userData.status === 1
                      ? 'color:#00CC00;font-weight:bold'
                      : 'color:#FF0000;font-weight:bold'
                  "
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: $route.meta.basicInformationRoute }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >BASIC INFORMATION
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.jobDetailsRoute }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >JOB DETAILS
        </b-nav-item>

        <b-nav-item
          :to="{ name: $route.meta.scheduleRoute }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >SCHEDULE
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.filesRoute }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >FILES
        </b-nav-item>
      </b-nav>
      <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view :key="$route.name" />
      </b-card>
    </div>
  </div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import { mapGetters, mapState } from "vuex";
import EmployeesService from "@/views/management/views/employees/employees.service";

export default {
  data() {
    return {
      form: {
        file: "",
        image: "",
        remove: false,
      },

      employee: null,
      keyPhoto: 1,
      finishedLoading: false,
    };
  },
  computed: {
    ...mapState({
      S_EMPLOYEE_ID: (state) => state.ManagementEmployeesStore.S_EMPLOYEE_ID,
      S_EMPLOYEE_INFO: (state) =>
        state.ManagementEmployeesStore.S_EMPLOYEE_INFO,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      userData: "ManagementEmployeesStore/G_EMPLOYEE_INFO",
      S_EMPLOYEE_ID: "ManagementEmployeesStore/G_EMPLOYEE_ID",
    }),
  },

  async created() {
    await this.$store.dispatch(
      "ManagementEmployeesStore/A_EMPLOYEE_INFO",
      this.$route.params.id
    );
    this.finishedLoading = true;
  },

  methods: {
    async deleteEmployee() {
      const params = {
        id: this.$route.params.id,
      };
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await EmployeesService.deleteEmployee(params);
          await this.$router.push({ name: "list-employees" });

          this.showSuccessSwal("Employee has been deleted successfully");
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    avatarText(fullName) {
      return avatarText(fullName);
    },
    addFile() {
      this.$refs.refInputEl.$el.childNodes[0].click();
    },
    fillFileData(event) {
      const image = event.srcElement.files[0];
      this.form.image = image.name;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => (this.form.file = reader.result);
      this.form.size = image.size;
      this.form.type = image.type;
      this.form.remove = true;
    },
    clearFileData() {
      this.form.file = "";
      this.form.image = "";
      this.form.remove = false;
      this.$refs.refInputEl.reset();
    },

    async updatePhoto() {
      try {
        this.addPreloader();

        const data = {
          image: this.form.file,
          namedoc: this.form.image,
          id: this.$route.params.id,
          size: this.form.size,
          user_id: this.currentUser.id_user,
          type: this.form.type,
          datexp: "",
        };

        if (this.form.file != "" && this.form.image != "") {
          const response = await EmployeesService.editPhoto(data);

          if (response.status == 200) {
            this.showGenericToast({
              text: "Photo updated sucessfully",
            });

            this.form.remove = false;

            await this.$store.dispatch(
              "ManagementEmployeesStore/A_EMPLOYEE_INFO",
              this.$route.params.id
            );
            this.form.file = "";
            this.keyPhoto++;
          }
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
.avatar-container {
  position: relative;

  .remove-temporal-avatar {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &.backdrop:hover {
    &::after {
      position: absolute;
      content: "";
      background: grey;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 10;
      top: 0px;
      left: 0px;
    }

    .remove-temporal-avatar {
      cursor: pointer;
      position: absolute;
      z-index: 100;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;

      div {
        width: 45px;
        height: 45px;
        background: transparent !important;
        border: 3px white solid;
        outline: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        z-index: 100;
      }
    }
  }
}

@media screen and(max-width: 1200px) and (min-width: 968px) {
  .information-form {
    margin-top: 30px;
  }
}
</style>
